<template>
  <div class=" mt-3 p-2">
    <b-container fluid class="mb-2">
      <b-row class="mb-2">
        <b-col>
          <h5 class="text-uppercase heading">Farm Retreats</h5>
        </b-col>
        <b-col class="text-right">
          <ProjectResortCreate
            @project-resorts-create="fetchData"
          ></ProjectResortCreate>
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col md="2">
          <!-- Select User Per Page  -->
          <b-form-select v-model="item_per_page" @change="changeItemNumber">
            <b-form-select-option value="10">10</b-form-select-option>
            <b-form-select-option value="50">50</b-form-select-option>
            <b-form-select-option value="100">100</b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col md="9">
          <!-- Filter -->
          <b-form-group label="" label-for="filter-input" class="mb-0">
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="1" class="d-flex justify-content-end">
          <b-button variant="success" @click="downloadCSV">
            <i class="fa fa-download" aria-hidden="true"></i>
          </b-button>
        </b-col>
      </b-row>

      <b-table
        class="dataTable rounded-left"
        :outlined="true"
        :items="items"
        :fields="fields"
        :busy="isBusy"
        :filter="filter"
        @filtered="filtered"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-desc.sync="sortDesc"
        head-variant="none"
        table-variant="none"
      >
        <template #cell(#)="data">
          <span v-if="currentPage == 1">{{ data.index + 1 }}</span>
          <span v-else>{{
            (currentPage - 1) * item_per_page + (data.index + 1)
          }}</span>
        </template>
        <template #cell(_projectID)="row">
          {{ row.item._projectID.name }}
        </template>
        <template #cell(location)="row">
          {{ row.item._projectID.location }}
        </template>
        <template #cell(image)="row">
          <img
            :src="row.item.image"
            class="rounded"
            width="50px"
            height="50px"
          />
        </template>
        <template #cell(actions)="row">
          <b-dropdown
            id="dropdown-1"
            text="Actions"
            variant="primary"
            size="sm"
          >
            <b-dropdown-item :to="'/projects/resorts/' + row.item._id">
              View
            </b-dropdown-item>
            <b-dropdown-item
              :to="'/projects/resorts/' + row.item._id + '/rooms'"
            >
              Cottage(s)
            </b-dropdown-item>
            <b-dropdown-item
              :to="'/projects/resorts/' + row.item._id + '/edit'"
            >
              Edit
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
      <div class="d-flex justify-content-center mt-4">
        <b-pagination
          pills
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </b-container>
  </div>
</template>

<script>
import ProjectResortCreate from '@/components/projects/resorts/ProjectResortCreate';
export default {
  components: {
    ProjectResortCreate,
  },
  name: 'list',
  data() {
    return {
      item_per_page: 10,
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      filter: null,
      isBusy: false,
      fields: [
        '#',
        'image',
        {
          key: '_projectID',
          label: 'Project Name',
        },
        {
          key: 'location',
          label: 'Location',
        },
        'actions',
      ],
      items: [],
      downloadItems: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    filtered(filteredItems) {
      this.downloadItems = filteredItems;
    },

    changeItemNumber() {
      this.perPage = this.item_per_page;
    },
    fetchData() {
      this.isBusy = true;
      window.axios.get('project-resorts').then((resp) => {
        this.items = resp.data.data;
        this.downloadItems = this.items;
        this.isBusy = false;
      });
    },
    downloadCSV() {
      let csv = '\ufeff' + 'PROJECT NAME,LOCATION\n';
      this.downloadItems.forEach((el) => {
        var line =
          el['_projectID'].name + ',' + el['_projectID'].location + '\n';
        csv += line;
      });
      var blob = new Blob([csv], { type: 'csv/plain' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Farm_Retreats.csv';
      link.click();
    },
  },
};
</script>

<style scoped>
.heading {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 3.5%;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #454545;
}

.view-icon {
  color: #1da040;
  height: 20px;
  width: 30px;
}

/deep/ .dataTable thead {
  background-color: #dcffd9;
  height: 64px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 3.5%;
  font-family: 'Poppins', sans-serif;
  padding-top: 14px;
  padding-bottom: 14px;
  color: #303030;
}

/deep/ .dataTable {
  text-align: center;
  margin-bottom: 21.5px;
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

/deep/ .dataTable tr td {
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  letter-spacing: 3.5%;
  line-height: 25.5px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
}
</style>
