<template>
  <div>
    <b-button size="sm" variant="success" v-b-modal.modal-2>
      New Farm Retreat</b-button
    >
    <b-modal id="modal-2" title="New Farm Retreat" size="xl" hide-footer>

      <b-alert variant="success" v-if="success" show> {{ success }}</b-alert>

        <b-form @submit.prevent="submitForm" enctype="multipart/form-data">
          <div class="row">
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group
                  id="input-group-1"
                  label="Select Farm:"
                  label-for="input-1"
              >

                  <b-form-select
                      v-model="form.project_id"
                      @change="projectSelected"
                      class="mb-3">
                    <b-form-select-option value="">Please select an Farm</b-form-select-option>
                    <b-form-select-option
                        :value="project._id"
                        v-for="(project, index) in projects"
                        :key="index"
                    >{{ project.name }}</b-form-select-option
                    >
                  </b-form-select>
                  <span class="error">{{ errors[0] }}</span>

              </b-form-group>
              </ValidationProvider>
              <div v-if="form.project_id">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-form-group
                      id="input-group-2"
                      label="Name:"
                      label-for="input-2"
                  >
                    <b-form-input
                        id="input-2"
                        type="text"
                        v-model="selected_project.name"

                        readonly
                    ></b-form-input>
                    <span class="error">{{ errors[0] }}</span>

                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-form-group
                      id="input-group-3"
                      label="Location:"
                      label-for="input-3"
                  >

                    <b-form-input
                        id="input-3"
                        type="text"
                        v-model="selected_project.location"
                        readonly

                    ></b-form-input>
                    <span class="error">{{ errors[0] }}</span>
                  </b-form-group>
                </ValidationProvider>
              </div>

              <b-form-group
                  id="input-group-2"
                  label="Ameneties:"
                  label-for="input-2"
              >

                  <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="form.amenities"
                      name="flavour-2"
                  >
                    <b-form-checkbox
                        v-for="(item, index) in amenities"
                        :key="index"
                        :value="item._id"
                    >{{ item.name }}</b-form-checkbox
                    >
                  </b-form-checkbox-group>


              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group
                  id="input-group-2"
                  label="Description:"
                  label-for="input-2"
              >
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-form-textarea
                      id="input-2"
                      v-model="form.description"
                      placeholder="Enter Farm Retreat description..."
                      rows="9"
                      max-rows="9"
                  ></b-form-textarea>
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>

              <b-form-group
                  id="input-group-2"
                  label="Resort Image:"
                  label-for="ProjectResortImage"
              >
                <input
                    type="file"
                       ref="ProjectResortImage"
                       @change="uploadImage"
                >

              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <h5>Member Credits</h5>
            </div>
           
            <div class="col-md-12">
              <table class="table table-sm">
                <thead class="small thead-light">
                <tr>
                  <th>#</th>
                  <th>Land Extends</th>
                  <th>Credits</th>
                 
                </tr>
                </thead>
                <tbody>
                <tr v-for="(credit, index) in form.credits" :key="index">
                  <td >{{ index + 1 }}</td>

                  <td>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-form-input
                          size="sm"
                          v-model="credit.acre"
                          placeholder="Enter acre"
                          required
                          readonly
                      >
                       
                      </b-form-input>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </td>
                  <td>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-form-input
                          class="form-control form-control-sm "
                          id="input-2"
                          v-model="credit.points"
                          placeholder="Enter Credits"
                          required
                      ></b-form-input>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </td>
                
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <b-button size="sm" variant="success" @click="submitForm()"  >
            Register
          </b-button>
        </b-form>



    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "ProjectResortCreate",
  data(){
    return {
      form:{
        project_id:'',
        amenities:[],
        description:'',
        credits: [
          
        ],
        image:''
      },
      amenities:[],
      projects: [],
      selected_project: false,
      success:false,
      acres: [0.25, 0.5, 1],
     

    }
  },
  mounted(){
      this.getProjects();
      this.getAmenities();
      this.setLandExtend();
  },
  methods:{
    setLandExtend(){
      this.acres.forEach(acre => {
         this.form.credits.push({
          points: "",
          acre: acre,
        })
      })
    },
    getAmenities(){
      axios.get('amenities')
          .then(res => {
            console.log(res.data)
            this.amenities = res.data.data;
          })
    },
    projectSelected(){
      let filteredProject = this.projects.filter(project => project._id == this.form.project_id);
      this.selected_project = filteredProject[0];
    },
    getProjects(){
      axios.get('project')
      .then(res => {
        this.projects = res.data.data;
      })
    },
    submitForm() {
      console.log(this.form);
      let formData1 = new FormData();
      formData1.append("project_id",this.form.project_id);
      formData1.append("amenities",JSON.stringify(this.form.amenities));
      formData1.append("credits",JSON.stringify(this.form.credits));
      formData1.append("description",this.form.description);
      formData1.append('image',this.form.image);
      window
          .axios({
            method: "post",
            url: "project-resorts",
            data: formData1,
            headers: { "Content-Type": "image/png" },
          })
          .then((resp) => {
            console.log(resp);
            if (resp.data.success) {
              this.success = resp.data.message;
              this.$emit("project-resorts-create", resp.data);
            }
            setTimeout(() => {
              (this.success = false) 
              this.$refs["modal-2"].hide();
             
            }, 3000);
          });
    },
    addRooms() {
      this.details.rooms.push({
        name: "",
        description: "",
        roomAmeneties: ["Television", "Air conditioner", "Kitchen"],
        credits: ["weekdays", "holidays", "weekends"],
        tarrif: ["weekdays", "holidays", "weekends"],
        serviceCharges: "",
      });
    },
    addCredits() {
      this.form.credits.push({
        acrs: [0.25, 0.5, 0.75, 1],
        points: "",
        selected_acrs: "",
      });
    },
    deleteCredits(index) {
      this.form.credits.splice(index, 1);
    },
    removeRooms(index) {
      this.details.rooms.splice(index, 1);
    },
    removeCredits(index) {
      if (this.points.length > 1) this.points.splice(index, 1);
    },
    uploadImage() {
      this.form.image = this.$refs.ProjectResortImage.files[0];
    },
  }

}
</script>

<style scoped>

</style>